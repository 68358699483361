import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
const contractTypeList = ['B2B Contract', 'Permanent Contract', 'Mandate Contract'];
const currencyList = 'AED|AFN|ALL|AMD|ANG|AOA|ARS|AUD|AWG|AZN|BAM|BBD|BDT|BGN|BHD|BIF|BMD|BND|BOB|BOV|BRL|BSD|BTN|BWP|BYR|BZD|CAD|CDF|CHE|CHF|CHW|CLF|CLP|CNY|COP|COU|CRC|CUC|CUP|CVE|CZK|DJF|DKK|DOP|DZD|EGP|ERN|ETB|EUR|FJD|FKP|GBP|GEL|GHS|GIP|GMD|GNF|GTQ|GYD|HKD|HNL|HRK|HTG|HUF|IDR|ILS|INR|IQD|IRR|ISK|JMD|JOD|JPY|KES|KGS|KHR|KMF|KPW|KRW|KWD|KYD|KZT|LAK|LBP|LKR|LRD|LSL|LYD|MAD|MDL|MGA|MKD|MMK|MNT|MOP|MRU|MUR|MVR|MWK|MXN|MXV|MYR|MZN|NAD|NGN|NIO|NOK|NPR|NZD|OMR|PAB|PEN|PGK|PHP|PKR|PLN|PYG|QAR|RON|RSD|RUB|RWF|SAR|SBD|SCR|SDG|SEK|SGD|SHP|SLL|SOS|SRD|SSP|STN|SVC|SYP|SZL|THB|TJS|TMT|TND|TOP|TRY|TTD|TWD|TZS|UAH|UGX|USD|USN|UYI|UYU|UZS|VEF|VND|VUV|WST|XAF|XCD|XDR|XOF|XPF|XSU|XUA|YER|ZAR|ZMW|ZWL';
const formatSalaryRange = salaryRange => { return salaryRange.replace(/\s/g, '').replace(/(\d)(?=(\d{3})+\b)/g, '$1 ').replace(/-/g, ' - ').replace(/from/g, 'from '); };
const formatContractType = textBetweenLocationSalary => { if (!textBetweenLocationSalary)
    return ''; const lowerCaseInput = textBetweenLocationSalary.toLowerCase(); const foundContractType = contractTypeList.find(substring => lowerCaseInput.includes(substring.toLowerCase())); if (foundContractType)
    return `(${foundContractType})`; return false; };
const formatLocation = location => { if (!location)
    return ''; return location.replace(/^(\s*\)\s*)?VAT\s+/i, '').replace(/\s*:/g, ':'); };
export const formatContractDetails = jobTitle => { const processedJobTitle = jobTitle.replace(/ +/g, ' ').replace(/contract:/gi, 'Contract'); const salaryRangePattern = '(\\d{1,2} \\d{3}|\\d{2,})'; const contractTypesPattern = `([a-zA-Z ]*B2B[a-zA-Z ]*) | [a-zA-Z ]*`; const countryOrCityPattern = `([a-zA-Z]+ ?)+: ?`; const regex = new RegExp(`\\b(${countryOrCityPattern})?((${contractTypesPattern}) ?)?(((${salaryRangePattern}) ?-|from) ?${salaryRangePattern})(?: (${currencyList}))( ?\\+ ?VAT)?\\b`, 'gi'); const matchingResults = _toConsumableArray(processedJobTitle.matchAll(regex)); const contractDetails = []; matchingResults.forEach(match => { const contractType = formatContractType(match[4] || ''); if (contractType !== false) {
    var _match$;
    const countryOrCity = formatLocation(match[1] || '');
    const salaryRange = formatSalaryRange(match[6] || '');
    const currency = ((_match$ = match[11]) === null || _match$ === void 0 ? void 0 : _match$.toUpperCase()) || '';
    const vat = match[12] ? '+ VAT' : '';
    const singleContractDetails = { salaryDetails: [countryOrCity, salaryRange, currency].join(' ').trim(), contractTypeDetails: [vat, contractType].join(' ').trim() };
    contractDetails.push(singleContractDetails);
} }); return contractDetails; };
