import TechDriven1 from './images/Techdriven1.png';
import TechDriven2 from './images/Techdriven2.png';
import TechDriven6 from './images/Techdriven6.png';
import Homelike3 from './images/Homelike3.png';
import Uncovering1 from './images/Uncovering1.png';
import Header1 from './images/Header1.png';
import Uncovering2 from './images/Uncovering2.png';
import TechDriven51 from './images/Techdriven51.png';
import Homelike4 from './images/Homelike4.png';
import Homelike2 from './images/Homelike2.png';
import Homelike21 from './images/Homelike21.png';
import Homelike51 from './images/Homelike51.png';
import Homelike1 from './images/Homelike1.png';
import Techdriven7 from './images/Techdriven7.png';
export const labels = { imageAlt: 'CodiLime Space', heading: 'About our space', boxes: [{ label: 'years on the market', value: '13+' }, { label: 'talents on board', value: '300+' }, { label: 'Limes are satisfied with their work at CodiLime', value: '92%' }, { label: 'Glassdoor average rating', value: '4.3/5' }], techDriven: { tech: 'tech', driven: 'driven' }, homeLike: { home: 'home', like: 'like' }, challange: 'Challenge yourself in tech projects.', atHome: "Make yourself at home in CodiLime's space.", neverStop: 'Never stop growing.', uncoveringPotential: { uncovering: 'uncovering', potential: 'potential' } };
export const images = { TechDriven1, TechDriven2, TechDriven6, Homelike3, Uncovering1, Header1, Uncovering2, TechDriven51, Homelike4, Homelike2, Homelike21, Homelike51, Homelike1, Techdriven7 };
